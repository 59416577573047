<template>
  <div class="onboarding-prepayment-container main-page-content-section text-center">
    <div class="text-lg mb-4">
      <p class="title md:text-5xl text-4xl">
        <span class="block">{{ $t('views.onboardingnopayment.title1') }}</span>
        {{ $t('views.onboardingnopayment.title2') }}
      </p>
    </div>

    <p class="text-base subtitle font-bold mb-2">{{ $t('views.onboardingnopayment.subtitle') }}</p>
    <Button class="md:w-auto w-full px-6 mt-4 py-3"
            @click="closeWebview()"
            icon="pi pi-folder"
            :label="$t('views.onboardingnopayment.gotoAssistantsButtonLabel')" />
  </div>
</template>

<script>
import router from "@/router";
import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
  data: function () {
    const store = useStore()

    const business = computed(() => store.state.selectedBusiness)
    return {
      router,
      business
    }
  },
  methods: {
    isBusinessPlus() {
      console.log("BUSINESS PRE PAYMENT: ", this.business);
      let templatematch = this.business.template.match(/^businessplus/)
      if(templatematch) {
        const value = templatematch.find(e => typeof e !== 'undefined');
        return value === "businessplus"
      }
      return false ;
    },
    closeWebview() {
      window.flutter_inappwebview.callHandler('onMrCallWebCallback', 'exit');
    }
  },
  mounted() {
    this.$gtag.event("onboarding_no_payment", {
      'business_id': this.business.businessId
    });
    window.scrollTo(0, 0);
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/colors';
@import '../assets/style/fonts';

</style>
