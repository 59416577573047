<template>
  <div class="account-section main-page-content-section">
    <ChangePassword></ChangePassword>
    <br>
    <br>
    <DeleteAccount></DeleteAccount>
  </div>
  <footer id="pre-footer">
    <PreFooterRequireAssistance/>
  </footer>
</template>

<script>
import ChangePassword from "@/components/ChangePassword";
import DeleteAccount from "@/components/DeleteAccount";
import PreFooterRequireAssistance from "@/components/PreFooterRequireAssistance";

export default {
  components: {ChangePassword, DeleteAccount, PreFooterRequireAssistance},
  setup() {
  },
  data() {
    const pageTexts = {
      it: {
      }
    }
    return {
      pageTexts
    }
  },
  created() {
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {}
}
</script>

<style lang="less" scoped>
#pre-footer {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>