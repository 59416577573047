import Account from "@/views/Account.vue";
import BusinessConfiguration from "@/views/business/BusinessConfiguration.vue";
import BusinessPhoneNumberVerification from "@/views/BusinessPhoneNumberVerification";
import Businesses from "@/views/Businesses"
import CallBooking from "@/views/CallBooking.vue";
import CallForwardingGuide from "@/views/CallForwardingGuide";
import Conversations from "@/views/Conversations";
import CustomTokenSignin from "@/views/CustomTokenSignin";
import FailedPayment from "@/views/FailedPayment"
import FailedPaymentProduct from "@/views/FailedPaymentProduct"
import Home from '@/views/Home.vue'
import MagicLink from "@/views/sign/MagicLink";
import OnboardingChooseDevice from "@/views/onboarding/OnboardingChooseDevice";
import OnboardingChoosePlan from "@/views/onboarding/OnboardingChoosePlan";
import OnboardingSimplifiedConfiguration1 from "@/views/onboarding/OnboardingSimplifiedConfiguration1";
import OnboardingConfigureAssistant from "@/views/onboarding/OnboardingConfigureAssistant";
import OnboardingForwardingOnLandline from "@/views/onboarding/OnboardingForwardingOnLandline";
import OnboardingForwardingOnOtherDevice from "@/views/onboarding/OnboardingForwardingOnOtherDevice";
import OnboardingForwardingOnThisDevice from "@/views/onboarding/OnboardingForwardingOnThisDevice";
import OnboardingLanguage from "@/views/onboarding/OnboardingLanguage";
import OnboardingMakeATestCall from "@/views/onboarding/OnboardingMakeATestCall";
import OnboardingAssistantCreated from "@/views/onboarding/OnboardingAssistantCreated";
import OnboardingNamePhone from "@/views/onboarding/OnboardingNamePhone";
import OnboardingNoPayment from "@/views/OnboardingNoPayment";
import OnboardingNotificationPreview from "@/views/onboarding/OnboardingNotificationPreview";
import OnboardingPreBookAppointment from "@/views/onboarding/OnboardingPreBookAppointment";
import OnboardingPrePayment from "@/views/OnboardingPrePayment";
import OnboardingSearchBusiness from "@/views/onboarding/OnboardingSearchBusiness";
import OnboardingSwitchboardConfiguration from "@/views/onboarding/OnboardingSwitchboardConfiguration";
import OnboardingThisOrOtherDevice from "@/views/onboarding/OnboardingThisOrOtherDevice";
import PartnerProgram from "@/views/PartnerProgram";
import Payment from "@/views/Payment";
import Plan from "@/views/Plan";
import PlanAndProducts from "@/views/PlanAndProducts.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Signin from "@/views/sign/Signin";
import Signup from "@/views/sign/Signup";
import SuccessPayment from "@/views/SuccessPayment"
import SuccessPaymentProduct from "@/views/SuccessPaymentProduct"
import TermsAndConditions from "@/views/TermsAndConditions";
import Whatsappweb from "@/views/Whatsappweb";
import {auth} from '@/firebase/config';
import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/callbooking',
    name: 'CallBooking',
    component: CallBooking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/magiclink',
    name: 'MagicLink',
    component: MagicLink
  },
  {
    path: '/customtokenlogin',
    name: 'CustomTokenSignin',
    component: CustomTokenSignin
  },
  {
    path: '/businesses',
    name: 'Businesses',
    component: Businesses,
    props: true,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: '/onboardinglang',
    name: 'OnboardingLanguage',
    component: OnboardingLanguage,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboardingnamephone',
    name: 'OnboardingNamePhone',
    component: OnboardingNamePhone,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboardingsearchbusiness',
    name: 'OnboardingSearchBusiness',
    component: OnboardingSearchBusiness,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboardingsimplifiedconfiguration1',
    name: 'OnboardingSimplifiedConfiguration1',
    component: OnboardingSimplifiedConfiguration1,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboardingconfigureassistant',
    name: 'OnboardingConfigureAssistant',
    component: OnboardingConfigureAssistant,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboardingnopayment',
    name: 'OnboardingNoPayment',
    component: OnboardingNoPayment,
    props: { }
  },
  {
    path: '/onboardingprepayment',
    name: 'OnboardingPrePayment',
    component: OnboardingPrePayment,
    props: { }
  },
  {
    path: '/onboardingmakeatestcall',
    name: 'OnboardingMakeATestCall',
    component: OnboardingMakeATestCall,
    props: { }
  },
  {
    path: '/onboardingassistantcreated',
    name: 'OnboardingAssistantCreated',
    component: OnboardingAssistantCreated,
    props: { }
  },
  {
    path: '/onboardingchooseplan',
    name: 'OnboardingChoosePlan',
    component: OnboardingChoosePlan,
    props: { }
  },
  {
    path: '/onboardingnotificationpreview',
    name: 'OnboardingNotificationPreview',
    component: OnboardingNotificationPreview,
    props: { }
  },
  {
    path: '/onboardingchoosedevice',
    name: 'OnboardingChooseDevice',
    component: OnboardingChooseDevice,
    props: { }
  },
  {
    path: '/onboardingprebookappointment',
    name: 'OnboardingPreBookAppointment',
    component: OnboardingPreBookAppointment,
    props: true
  },
  {
    path: '/onboardingthisorotherdevice',
    name: 'OnboardingThisOrOtherDevice',
    component: OnboardingThisOrOtherDevice,
    props: true
  },
  {
    path: '/onboardingforwardingonthisdevice',
    name: 'OnboardingForwardingOnThisDevice',
    component: OnboardingForwardingOnThisDevice,
    props: true
  },
  {
    path: '/onboardingforwardingonotherdevice',
    name: 'OnboardingForwardingOnOtherDevice',
    component: OnboardingForwardingOnOtherDevice,
    props: true
  },
  {
    path: '/onboardingforwardingonlandline',
    name: 'OnboardingForwardingOnLandline',
    component: OnboardingForwardingOnLandline,
    props: true
  },
  {
    path: '/onboardingswitchboardconfiguration',
    name: 'OnboardingSwitchboardConfiguration',
    component: OnboardingSwitchboardConfiguration,
    props: true
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    props: { }
  },
  {
    path: '/businessphonenumberverification',
    name: 'BusinessPhoneNumberVerification',
    component: BusinessPhoneNumberVerification,
    props: true
  },
  {
    path: '/businessconfiguration',
    name: 'BusinessConfiguration',
    component: BusinessConfiguration,
    props: true
  },
  {
    path: '/plan',
    name: 'Plan',
    component: Plan,
    props: { },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/planandproducts',
    name: 'PlanAndProducts',
    component: PlanAndProducts,
    props: { },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/success_payment',
    name: 'SuccessPayment',
    component: SuccessPayment,
    props: true
  },
  {
    path: '/success_payment_product',
    name: 'SuccessPaymentProduct',
    component: SuccessPaymentProduct,
    props: true
  },
  {
    path: '/failed_payment',
    name: 'FailedPayment',
    component: FailedPayment,
    props: true
  },
  {
    path: '/failed_payment_product',
    name: 'FailedPaymentProduct',
    component: FailedPaymentProduct,
    props: true
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    props: true
  },
  {
    path: '/termsandconditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    props: true
  },
  {
    path: '/callforwardingguide',
    name: 'CallForwardingGuide',
    component: CallForwardingGuide,
    props: true
  },
  {
    path: '/partnerprogram',
    name: 'PartnerProgram',
    component: PartnerProgram,
    props: true
  },
  {
    path: '/conversations',
    name: 'Conversations',
    component: Conversations,
    props: true
  },
  {
    path: '/whatsappweb',
    name: 'Whatsappweb',
    component: Whatsappweb,
    props: true
  },
  {
    path: '/signup',
    name: 'SignupNew',
    component: Signup,
    props: true
  },
  {
    path: '/signin',
    name: 'SigninNew',
    component: Signin,
  },
  {
    path: '/login',
    name: 'Signin',
    component: Signin
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

const getUser = async () => {
  const response = new Promise((resolve, reject) => {
    auth.onAuthStateChanged(user => {
      resolve(user)
    }, err => {
      reject(err)
    })
  })

  return response;
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    const user = await getUser();
    return user && user.accessToken && user?.emailVerified && !user.isAnonymous
        ? next()
        : next('login');
  }

  next();
});

export default router
